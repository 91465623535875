<!--
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-03-22 11:58:23
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-03-22 15:31:30
 * @FilePath: \cz-operation-web\src\views\systemManage\modal\addScope.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    :visible="visible"
    title="新建Scope"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item>
        <a-input v-decorator="['name']"></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'AddScope',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
    }
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('被输出值{ values }的输出结果是：', values)
          /* 新增scope */
        }
      })
    },
    handleCancel() {
      this.$emit('update:visible', false)
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped></style>
