<template>
  <div class="apiList">
    <a-form
      :form="form"
      layout="inline"
      style="margin-bottom: 20px; position: relative"
    >
      <a-form-item label="请求路径">
        <a-input placeholder="请输入关键词" v-decorator="['path']"></a-input>
      </a-form-item>
      <a-form-item label="接口名称">
        <a-input
          placeholder="请输入应用名称"
          v-decorator="['apiName']"
        ></a-input>
      </a-form-item>
      <a-form-item label="接口编码">
        <a-input
          placeholder="请输入应用名称"
          v-decorator="['apiCode']"
        ></a-input>
      </a-form-item>
      <a-form-item label="服务名">
        <a-input
          placeholder="请输入应用名称"
          v-decorator="['serviceId']"
        ></a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" style="margin-right: 8px" @click="search"
          >查询</a-button
        >
        <a-button @click="reset">重置</a-button>
      </a-form-item>
      <!-- <div class="set">
        <a-dropdown>
          <a-icon type="setting" />
          <a-menu slot="overlay">
            <a-menu-item @click="scopeManageVisible = true"
              >Scope管理</a-menu-item
            >
            <a-menu-item @click="addScopeVisible = true">新建Scope</a-menu-item>
          </a-menu>
        </a-dropdown>
      </div> -->
    </a-form>

    <a-button type="primary" @click="addApi">添加</a-button>
    <a-dropdown v-if="selectedRowKeys.length > 0">
      <a-menu slot="overlay" @click="handleMenuClick">
        <a-menu-item key="1">删除</a-menu-item>
        <a-sub-menu key="2" title="状态">
          <a-menu-item key="2-1">禁用</a-menu-item>
          <a-menu-item key="2-2">启用</a-menu-item>
          <a-menu-item key="2-3">维护中</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="3" title="公开访问">
          <a-menu-item key="3-1">拒绝</a-menu-item>
          <a-menu-item key="3-2">允许</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="4" title="身份认证">
          <a-menu-item key="4-1">关闭</a-menu-item>
          <a-menu-item key="4-2">开启</a-menu-item>
        </a-sub-menu>
      </a-menu>
      <a-button style="margin-left: 8px">
        批量操作 <a-icon type="down" />
      </a-button>
    </a-dropdown>
    <div class="des_box">
      <a-tag color="blue">
        <div>
          <a-icon type="info-circle" theme="filled" style="color: #2d8cf0" />
          自动扫描<span>@EnabledResourceServer</span>资源服务器接口信息，注：自动添加的接口，都是未公开的。<span
            >只有公开的接口，才可以通过网关访问。否则将提示：“请求地址，拒绝访问！”</span
          >
        </div>
      </a-tag>
    </div>
    <a-table
      :columns="columns"
      :data-source="apiList"
      bordered
      :pagination="pagination"
      @change="tableChange"
      :scroll="{ x: 1600 }"
      :rowKey="(record) => record.apiId"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      style="margin-top: 15px"
    >
      <div slot="apiAuth" slot-scope="text, record">
        <a-tag :color="record.isOpen ? 'green' : 'red'">{{
          record.isOpen ? '允许公开访问' : '拒绝公开访问'
        }}</a-tag>
        <a-tag :color="record.isAuth ? 'green' : 'red'">{{
          record.isAuth ? '开启身份认证' : '关闭身份认证'
        }}</a-tag>
        <a-tag :color="record.status == 1 ? 'green' : 'red'">{{
          record.status == 1 ? '启用' : record.status == 0 ? '禁用' : '维护中'
        }}</a-tag>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a @click="editApi(record)" style="margin-right: 10px">编辑</a>
        <a @click="deleteApi(record)" style="margin-right: 10px">删除</a>
      </div>
    </a-table>
    <!-- api设置/编辑/添加 -->
    <api-info
      :visible.sync="apiVisible"
      :record="apiRecord"
      v-if="apiVisible"
      @refresh="refresh"
    ></api-info>
    <!-- 新建Scope -->
    <add-scope
      :visible="addScopeVisible"
      v-if="addScopeVisible"
      @close="addScopeVisible = false"
    ></add-scope>
    <scope-manage
      :visible="scopeManageVisible"
      v-if="scopeManageVisible"
      @close="scopeManageVisible = false"
    ></scope-manage>
  </div>
</template>

<script>
import apiInfo from './modal/apiInfo.vue'
import addScope from './modal/addScope.vue'
import scopeManage from './modal/scopeManage.vue'

const columns = [
  {
    dataIndex: 'apiCode',
    key: 'apiCode',
    title: '编码',
    width: '250px',
    align: 'left',
  },
  {
    dataIndex: 'apiName',
    key: 'apiName',
    title: '名称',
    width: '150px',
    align: 'left',
  },
  {
    dataIndex: 'path',
    key: 'path',
    title: '地址',
    width: '200px',
    align: 'left',
  },
  {
    dataIndex: 'apiCategory',
    key: 'apiCategory',
    title: '分类',
    width: '80px',
    align: 'left',
  },
  {
    dataIndex: 'serviceId',
    key: 'serviceId',
    title: '服务名称',
    align: 'left',

    // width: '100px',
  },
  {
    dataIndex: 'scope',
    key: 'scope',
    title: 'Scope',
    align: 'left',
  },
  {
    dataIndex: 'apiAuth',
    key: 'apiAuth',
    title: '接口安全',
    scopedSlots: { customRender: 'apiAuth' },
    width: '300px',
    align: 'left',
  },
  {
    dataIndex: 'apiDesc',
    key: 'apiDesc',
    title: '描述',
    // width: '150px',
    align: 'left',
  },
  {
    dataIndex: 'updateTime',
    key: 'updateTime',
    title: '最后更新时间',
    width: '150px',
    align: 'left',
  },

  {
    dataIndex: 'operation',
    key: 'operation',
    title: '操作',
    scopedSlots: { customRender: 'operation' },
    fixed: 'right',
    width: '130px',
    align: 'left',
  },
]
export default {
  name: 'ApiList',
  components: { apiInfo, addScope, scopeManage },
  data() {
    return {
      form: this.$form.createForm(this),
      columns,
      apiList: [],
      pagination: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 20,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        pageSizeOptions: ['10', '15', '20'],
      },

      apiVisible: false,
      apiRecord: {},
      selectedRowKeys: [],
      addScopeVisible: false,
      scopeManageVisible: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getAppData({ limit: 10, page: 1 })
    },
    /* 表格单选框变化 */
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      console.log(this.selectedRowKeys)
    },
    handleMenuClick(e) {
      console.log(e)
      let data = {
        ids: this.selectedRowKeys.join(','),
      }
      switch (e.key) {
        case '1':
          /* 批量删除 */
          this.$api.batchDeleteApi(data).then((res) => {
            if (res.code === 0) {
              this.$message.success('删除成功')
              this.refresh()
            } else {
              this.$message.error('删除失败')
            }
          })
          break
        case '2-1':
          /* 批量禁用 */
          this.$set(data, 'status', 0)
          this.$api.batchUpdateApiStatus(data).then((res) => {
            if (res.code === 0) {
              this.$message.success('修改成功')
              this.refresh()
            } else {
              this.$message.error('修改失败')
            }
          })
          break
        case '2-2':
          /* 批量启用*/
          this.$set(data, 'status', 1)
          this.$api.batchUpdateApiStatus(data).then((res) => {
            if (res.code === 0) {
              this.$message.success('修改成功')
              this.refresh()
            } else {
              this.$message.error('修改失败')
            }
          })
          break
        case '2-3':
          /* 批量维护中 */
          break
        case '3-1':
          /* 批量拒绝 */
          this.$set(data, 'open', 0)
          this.$api.batchUpdateApiOpen(data).then((res) => {
            if (res.code === 0) {
              this.$message.success('修改成功')
              this.refresh()
            } else {
              this.$message.error('修改失败')
            }
          })
          break
        case '3-2':
          /* 批量允许 */
          this.$set(data, 'open', 1)
          this.$api.batchUpdateApiOpen(data).then((res) => {
            if (res.code === 0) {
              this.$message.success('修改成功')
              this.refresh()
            } else {
              this.$message.error('修改失败')
            }
          })
          break
        case '4-1':
          /* 批量关闭 */
          this.$set(data, 'auth', 0)
          this.$api.batchUpdateApiAuth(data).then((res) => {
            if (res.code === 0) {
              this.$message.success('修改成功')
              this.refresh()
            } else {
              this.$message.error('修改失败')
            }
          })
          break
        case '4-2':
          /* 批量开启 */
          this.$set(data, 'auth', 1)
          this.$api.batchUpdateApiAuth(data).then((res) => {
            if (res.code === 0) {
              this.$message.success('修改成功')
              this.refresh()
            } else {
              this.$message.error('修改失败')
            }
          })
          break
      }
    },
    /* 获取app列表 */
    getAppData(params) {
      let searchValue = this.form.getFieldsValue()
      console.log(searchValue)
      params = {
        ...params,
        ...searchValue,
      }
      return new Promise((resolve, reject) => {
        this.$api
          .getApiInfoList(params)
          .then((res) => {
            console.log(res, '应用列表')
            if (res.code === 0) {
              this.apiList = res.data.records
              this.pagination.total = Number(res.data.total)
            } else {
              this.$message('接口列表获取失败')
            }
          })
          .catch((err) => {})
      })
    },
    search() {
      this.getAppData({
        page: 1,
        limit: this.pagination.pageSize,
      })
    },
    reset() {
      this.form.resetFields()
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getAppData({
        page: 1,
        limit: 10,
      })
    },
    /* 表格变化（分页） */
    tableChange(pagination) {
      let { current, pageSize } = pagination
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getAppData({ page: current, limit: pageSize })
    },
    editApi(record) {
      //   console.log(record)
      this.apiRecord = record
      this.apiVisible = true
    },
    refresh() {
      this.apiVisible = false
      // this.init()
      this.getAppData({
        limit: this.pagination.pageSize,
        page: this.pagination.current,
      })
    },
    /* 添加客户端app */
    addApi() {
      this.apiRecord = {}
      this.apiVisible = true
    },
    /* 重置密钥 */
    // reSet(record) {
    //   let _this = this
    //   this.$confirm({
    //     title: '重置后将影响应用正常使用，确定重置吗？',
    //     onOk() {
    //       _this.$api.reset(record.appId).then((res) => {
    //         console.log(res, '重置秘钥')
    //         if (res.code == 0) {
    //           _this.$message.success('重置成功')
    //           _this.refresh()
    //         } else {
    //           _this.$message.error(res.message)
    //         }
    //       })
    //     },
    //     onCancel() {},
    //   })
    // },
    /* 删除api */
    deleteApi(record) {
      let _this = this
      this.$confirm({
        title: '确定删除吗？',
        onOk() {
          _this
            .delApi({ apiId: record.apiId })
            .then(() => {
              _this.$message.success('删除成功')
              _this.refresh()
            })
            .catch(() => {
              _this.$message.error('删除失败')
            })
        },
        onCancel() {},
      })
    },
    /* 确定删除api */
    delApi(data) {
      return new Promise((resolve, reject) => {
        this.$api.deleteApi(data).then((res) => {
          if (res.code === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.apiList {
  background-color: #fff;
  padding: 15px;
}

/deep/ .ant-table-body {
  background-color: #fff;
  font-size: 12px;
}

/deep/ .ant-table-row-cell-break-word {
  font-size: 12px;
}

/deep/ .ant-table-column-title {
  font-weight: bold;
}

/deep/ .ant-table-align-center,
.ant-table-align-left {
  height: 42px !important;
  padding: 0;
  font-family: 'PingFang SC Bold';
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #333;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 10px 16px 10px 16px;
}

/deep/ .des_box {
  margin-top: 10px;
  .ant-tag {
    width: 100%;
    height: 30px;
    div {
      height: 100%;
      line-height: 30px;
      color: #515a6e;
      span {
        color: #c7254e;
        background-color: #f6f6f6;
      }
    }
  }
}
.set {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 25px;
}
</style>
