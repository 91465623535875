<!--
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-08-22 15:44:29
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-08-23 16:57:52
 * @FilePath: \cz-operation\src\views\systemManage\modal\apiInfo.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    :visible="visible"
    :title="isEdit ? '编辑接口' : '添加接口'"
    @cancel="handleCancel"
    @ok="handleOk"
    width="800px"
  >
    <div class="des_box">
      <a-tag color="blue">
        <div>
          <a-icon type="info-circle" theme="filled" style="color: #2d8cf0" />
          自动扫描接口swagger注解。<a style="color: #2d8cf0">示例代码</a>
        </div>
      </a-tag>
    </div>
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="服务名称">
        <a-select
          v-decorator="[
            'serviceId',
            {
              initialValue: record.serviceId || '',
              rules: [{ required: true, message: '所属服务不能为空' }],
            },
          ]"
        >
          <a-select-option
            v-for="item in gatewayServiceList"
            :key="item.serviceId"
            >{{ item.serviceName }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="接口分类">
        <a-input
          v-decorator="[
            'apiCategory',
            {
              initialValue: record.apiCategory || 'default',
              rules: [{ required: true, message: '接口分类不能为空' }],
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="接口编码">
        <a-input
          v-decorator="[
            'apiCode',
            {
              initialValue: record.apiCode || '',
              rules: [{ required: true, message: '接口编码不能为空' }],
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="接口名称">
        <a-input
          v-decorator="[
            'apiName',
            {
              initialValue: record.apiName || '',
              rules: [{ required: true, message: '接口名称不能为空' }],
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="请求地址">
        <a-input
          v-decorator="[
            'path',
            {
              initialValue: record.path || '',
              rules: [{ required: true, message: '请求地址不能为空' }],
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="优先级">
        <a-input-number
          v-decorator="[
            'priority',
            {
              initialValue: record.priority || 0,
            },
          ]"
        ></a-input-number>
      </a-form-item>
      <a-form-item label="身份认证">
        <a-radio-group
          v-decorator="['isAuth', { initialValue: record.isAuth || 0 }]"
        >
          <a-radio-button :value="0">关闭</a-radio-button>
          <a-radio-button :value="1">开启</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="公开访问">
        <a-radio-group
          v-decorator="['isOpen', { initialValue: record.isOpen || 0 }]"
        >
          <a-radio-button :value="0">拒绝</a-radio-button>
          <a-radio-button :value="1">允许</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="状态">
        <a-radio-group
          v-decorator="['status', { initialValue: record.status || 0 }]"
        >
          <a-radio-button :value="0">禁用</a-radio-button>
          <a-radio-button :value="1">启用</a-radio-button>
          <a-radio-button :value="2">维护中</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="描述">
        <a-textarea
          :rows="4"
          placeholder="请输入内容"
          v-decorator="['apiDesc', { initialValue: record.apiDesc || '' }]"
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'ApiInfo',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isEdit: Object.keys(this.record).length > 0,
      form: this.$form.createForm(this),
      gatewayServiceList: [],
    }
  },
  created() {
    this.getServiceList()
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values)
          if (this.isEdit) {
            /* 修改api */
            values = {
              ...values,
              apiId: this.record.apiId,
            }
            this.updateApi(values)
              .then(() => {
                this.$message.success('修改成功')
                this.$emit('refresh')
              })
              .catch(() => {
                this.$message.error('修改失败')
              })
          } else {
            /* 新增api */
            this.addApi(values)
              .then(() => {
                this.$message.success('添加成功')
                this.$emit('refresh')
              })
              .catch(() => {
                this.$message.error('添加失败')
              })
          }
        }
      })
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    /* 获取网关服务列表 */
    getServiceList() {
      this.$api.getGatewayServiceList().then((res) => {
        if (res.code === 0) {
          this.gatewayServiceList = res.data
        } else {
          this.$message.warning('获取服务列表失败')
        }
      })
    },

    /* 添加api */
    addApi(data) {
      return new Promise((resolve, reject) => {
        this.$api.addApi(data).then((res) => {
          if (res.code == 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 修改api */
    updateApi(data) {
      return new Promise((resolve, reject) => {
        this.$api.updateApi(data).then((res) => {
          if (res.code == 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .des_box {
  margin-top: 10px;
  .ant-tag {
    width: 100%;
    height: 30px;
    div {
      height: 100%;
      line-height: 30px;
      color: #515a6e;
      span {
        color: #c7254e;
        background-color: #f6f6f6;
      }
    }
  }
}
</style>
