<!--
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-03-22 15:14:04
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-03-22 15:28:16
 * @FilePath: \cz-operation-web\src\views\systemManage\modal\scopeManage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    :visible="visible"
    title="Scope管理"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-table :data-source="scopeList" :columns="columns" bordered>
      <div slot="opt" slot-scope="text, record">
        <a @click="deleteScope(record)">删除</a>
      </div>
    </a-table>
  </a-modal>
</template>

<script>
const columns = [
  {
    title: 'Scope',
    dataIndex: 'scope',
    key: 'scope',
  },
  {
    title: '操作',
    dataIndex: 'opt',
    key: 'opt',
    scopedSlots: { customRender: 'opt' },
  },
]

export default {
  name: 'ScopeManage',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns,
      scopeList: [],
    }
  },
  created() {
    this.getScopeList()
  },
  methods: {
    handleOk() {},
    handleCancel() {
      // this.$emit('update:visible', false)
      this.$emit('close')
    },
    deleteScope(record) {
      console.log('被输出值{ record }的输出结果是：', record)
    },
    /* 获取scope列表 */
    async getScopeList() {
      try {
        let res = await this.$api.getScopeListApi()
        if (res.data == 0) {
          this.scopeList = res.data
        }
      } catch (err) {
        console.log('被输出值{ err }的输出结果是：', err)
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
