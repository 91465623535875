var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.isEdit ? '编辑接口' : '添加接口',"width":"800px"},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('div',{staticClass:"des_box"},[_c('a-tag',{attrs:{"color":"blue"}},[_c('div',[_c('a-icon',{staticStyle:{"color":"#2d8cf0"},attrs:{"type":"info-circle","theme":"filled"}}),_vm._v(" 自动扫描接口swagger注解。"),_c('a',{staticStyle:{"color":"#2d8cf0"}},[_vm._v("示例代码")])],1)])],1),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-form-item',{attrs:{"label":"服务名称"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'serviceId',
          {
            initialValue: _vm.record.serviceId || '',
            rules: [{ required: true, message: '所属服务不能为空' }],
          } ]),expression:"[\n          'serviceId',\n          {\n            initialValue: record.serviceId || '',\n            rules: [{ required: true, message: '所属服务不能为空' }],\n          },\n        ]"}]},_vm._l((_vm.gatewayServiceList),function(item){return _c('a-select-option',{key:item.serviceId},[_vm._v(_vm._s(item.serviceName))])}),1)],1),_c('a-form-item',{attrs:{"label":"接口分类"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'apiCategory',
          {
            initialValue: _vm.record.apiCategory || 'default',
            rules: [{ required: true, message: '接口分类不能为空' }],
          } ]),expression:"[\n          'apiCategory',\n          {\n            initialValue: record.apiCategory || 'default',\n            rules: [{ required: true, message: '接口分类不能为空' }],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"接口编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'apiCode',
          {
            initialValue: _vm.record.apiCode || '',
            rules: [{ required: true, message: '接口编码不能为空' }],
          } ]),expression:"[\n          'apiCode',\n          {\n            initialValue: record.apiCode || '',\n            rules: [{ required: true, message: '接口编码不能为空' }],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"接口名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'apiName',
          {
            initialValue: _vm.record.apiName || '',
            rules: [{ required: true, message: '接口名称不能为空' }],
          } ]),expression:"[\n          'apiName',\n          {\n            initialValue: record.apiName || '',\n            rules: [{ required: true, message: '接口名称不能为空' }],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"请求地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'path',
          {
            initialValue: _vm.record.path || '',
            rules: [{ required: true, message: '请求地址不能为空' }],
          } ]),expression:"[\n          'path',\n          {\n            initialValue: record.path || '',\n            rules: [{ required: true, message: '请求地址不能为空' }],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"优先级"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'priority',
          {
            initialValue: _vm.record.priority || 0,
          } ]),expression:"[\n          'priority',\n          {\n            initialValue: record.priority || 0,\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"身份认证"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['isAuth', { initialValue: _vm.record.isAuth || 0 }]),expression:"['isAuth', { initialValue: record.isAuth || 0 }]"}]},[_c('a-radio-button',{attrs:{"value":0}},[_vm._v("关闭")]),_c('a-radio-button',{attrs:{"value":1}},[_vm._v("开启")])],1)],1),_c('a-form-item',{attrs:{"label":"公开访问"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['isOpen', { initialValue: _vm.record.isOpen || 0 }]),expression:"['isOpen', { initialValue: record.isOpen || 0 }]"}]},[_c('a-radio-button',{attrs:{"value":0}},[_vm._v("拒绝")]),_c('a-radio-button',{attrs:{"value":1}},[_vm._v("允许")])],1)],1),_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: _vm.record.status || 0 }]),expression:"['status', { initialValue: record.status || 0 }]"}]},[_c('a-radio-button',{attrs:{"value":0}},[_vm._v("禁用")]),_c('a-radio-button',{attrs:{"value":1}},[_vm._v("启用")]),_c('a-radio-button',{attrs:{"value":2}},[_vm._v("维护中")])],1)],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['apiDesc', { initialValue: _vm.record.apiDesc || '' }]),expression:"['apiDesc', { initialValue: record.apiDesc || '' }]"}],attrs:{"rows":4,"placeholder":"请输入内容"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }